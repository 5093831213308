<template>
  <st-side-page class="settings-main-page">
    <portal to="page-name">Conta</portal>
    <template v-slot:side>
      <ul class="menu">
        <li class="menu-item" v-for="(menu, i) in menus" :key="i">
          <router-link :to="menu.path">{{ menu.label }}</router-link>
          <fa-icon :icon="['fal', 'chevron-right']"/>
        </li>
      </ul>
    </template>
    <router-view />
  </st-side-page>
</template>

<script>
export default {
  data() {
    return {
      menus: [
        { path: '/customer', label: 'Conta' },
        { path: '/customer/payments', label: 'Pagamentos' },
      ],
    };
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_variables.scss";

.settings-main-page {
  &.side-page {
    .side-page-side {
      flex: 0 0 11rem;
    }
  }
  .menu {
    .router-link-exact-active {
      color: $primary-color;
      & + svg {
        color: $primary-color;
      }
    }
  }
}
</style>
